import { PostDevice } from 'src/app/shared/model/device';
import { MeasuredPoint } from 'src/app/shared/model/measured-point.model';
import { Meter } from 'src/app/shared/model/meter.model';
import { Site } from 'src/app/shared/model/site.model';
import { Thing } from 'src/app/shared/model/thing.model';

export interface ReportMessage {
  step: string;
  state: string;
  message: string;
  details: string;
  icon: string;
  errors: [];
  info: object;
  loading?: boolean;
}

export enum DATA_PROVIDERS {
  ENEL_IOT_FLEXIBILITY = 'd4b783a4-5ce6-4358-a1eb-2d064105f790'
}

export enum STATE {
  fail = 'FAILED',
  success = 'SUCCESS',
  partial = 'PARTIAL',
}

export enum STATE_ICON {
  error = 'error_outline',
  success = 'check_circle_outline',
  warning = 'warning_amber',
  rollback = 'settings_backup_restore',
}

export enum STEP {
  meter = 'meter',
  measuredPoints = 'measured_points',
  device = 'device',
  undoMeter = 'undo_meter',
}

export enum MESSAGE {
  success = 'success_message',
  failed = 'failed_message',
  partial = 'partial_points_message',
  noPoints = 'no_points_to_be_created',
  rollback = 'rollback_message',
  rollbackFail = 'rollback_fail_message',
}

export enum VIEWS {
  report = 'REPORT',
  confirm = 'CONFIRM',
}

export enum DETAILS {
  meterRollback = 'meter_rollback',
  meterRollbackFailed = 'rollback_failed',
  pointsFailed = 'points_failed',
  meterFailed = 'meter_failed',
  partialPointsFailed = 'partial_points_failed',
  deviceFailed = 'device_failed',
}

export class EntityBulkCreationData {
  thing: Thing;
  site: Site;
  pulseMultiplier: number;
  locale: string;

  constructor(thing: Thing, site: Site, pulseMultiplier: number, locale: string) {
    this.thing = thing;
    this.site = site;
    this.pulseMultiplier = pulseMultiplier;
    this.locale = locale;
  }

  mapDataToMeter(): Meter {
    const {
      parameters: { serial_number, thing_id },
    } = this.thing;

    const { displayLabel: siteName, id: siteId, timezone } = this.site;

    return {
      alternate_ids: {},
      default_locale: this.locale,
      descriptions: {},
      display_labels: {
        en_US: `${siteName}-${thing_id}`,
      },
      equipment_type: 'METER',
      name: `${siteName}-${thing_id}`,
      short_display_labels: {},
      space_type: 'Meter',
      status: 'ACTIVE',
      timezone: timezone || 'America/New_York',
      site_id: siteId,
      device_id: serial_number,
      dataprovider_id: DATA_PROVIDERS.ENEL_IOT_FLEXIBILITY,
    };
  }

  mapDataToMeasuredPoints(meterId: string): MeasuredPoint[] {
    const {
      parameters: { filter_tag, thing_id, environment_prefix },
    } = this.thing;

    const { displayLabel: siteName, timezone } = this.site;

    if (!filter_tag || filter_tag.length === 0) {
      return [];
    }

    const points = filter_tag.map((tag) => {
      return {
        alternate_ids: {
          SOURCE_ID: `${environment_prefix}_${thing_id}_${tag.id}`,
        },
        correction_algorithm: '',
        dataprovider_id: DATA_PROVIDERS.ENEL_IOT_FLEXIBILITY,
        meter_id: meterId,
        trend_id: `${tag.id}`,
        multiplier: this.pulseMultiplier || 1,
        reporting_interval: 1,
        reporting_interval_ms: 60000,
        display_labels: { en_US: `${siteName}-${thing_id}` },
        name: `${siteName}-${thing_id}-${tag.id}`,
        default_locale: 'en_US',
        space_type: 'Measured Point',
        timezone: timezone || 'America/New_York',
        source: `${environment_prefix}_${thing_id}_${tag.id}`,
        expression_factor: 1,
        follows: [],
        is_cumulative: true,
        status: 'ACTIVE',
        vee_settings: {
          vee_high_read_threshold: 50000,
          vee_zero_check: false,
          vee_high_read_check: true,
          vee_gap_check: true,
          vee_negative_read_check: true,
        },
      };
    });

    return points;
  }

  mapDataToDevice(meterId: string): PostDevice {
    return {
      defaultLocale: this.locale,
      displayLabels: { [this.locale]: this.thing.device_id, en_US: this.thing.device_id },
      equipmentIds: [meterId],
      status: 'ACTIVE',
      siteId: this.site.id,
      intervalDataTypes: {},
      emulated: false,
      includeInCalcs: true,
      notes: 'Created automatically from the Thing ID',
    };
  }
}
